import React from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';

const About = () => {
  const clientLogos = [
    "Delta Garments",
    "Source International",
    "Amin Apparel",
    "3A Apparel",
    "Muster Haft Pvt & Apparel",
    "WE Apparel",
    "Top Trade Clothing",
    "Shadman Cotton Mill Pvt",
    "Styler Plus",
    "Ali Murtaza Associates",
    "Firhaj (Hush Puppies)",
    "Mima Apparel",
    "Usman Knitwear",
    "Aysha Knitwear",
    "SB Apparel",
    "Indus Plus",
    "Denom Plus",
    "Sheikh Of Sialkot",
    "Sheikh Nawab Sons",
    "Shafi Texcel Limited",
    "Samreen Herbal Pharma",
    "Hale Pharma",
    "Faaz International",
    "Green Paint Industry",
    "Laiqa Foods",
    "Gluck Laboratories",
    "Moor Green Chemicals",
    "Medinoon Pharma",
    "Agro Power",
    "Valley Foods",
    "Sobia Nazir officials",
    "AM Styles",
    "Abdul Wahab Pharma"
  ];


  const sliderSettings = {
    dots: false, // Display navigation dots
    infinite: true, // Loop the carousel
    speed: 500, // Animation speed in ms
    slidesToShow: 4, // Number of logos visible at a time
    slidesToScroll: 1, // Number of logos to scroll at a time
    autoplay: true, // Auto-scroll
    autoplaySpeed: 2000, // Delay between auto-scrolls
    pauseOnHover: true, // Pause auto-scroll on hover
    swipeToSlide: true, // Allows mouse/touch drag to slide
    responsive: [
      {
        breakpoint: 1024, // Adjust settings for screens smaller than 1024px
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600, // Adjust settings for screens smaller than 600px
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // Adjust settings for screens smaller than 480px
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="max-w-4xl mx-auto px-5 py-10">
      <Helmet> 
        <title>About Us - RC Packages</title>
        <meta
          name="description"
          content="Learn about RC Packages - your reliable partner for innovative, cost-effective, and sustainable packaging solutions."
        />
        <meta
          name="keywords"
          content="RC Packages, packages, packaging solutions, sustainable packaging, paperboard folding carton, 24/7 manufacturing, recyclable products, quality control"
        />
      </Helmet>
      <h1 className="text-center text-3xl font-bold mb-6">About Us</h1>
      <div className="mb-8 text-justify">
        <p className="mb-4">
          At RC Packages, we understand the importance of reliable packaging solutions in today's competitive market. That's why we strive to provide innovative, cost-effective, and sustainable packaging solutions to businesses of all sizes. Our state-of-the-art manufacturing facilities and skilled team ensure that every box we produce meets the highest standards of quality and durability. RC packages is one of the country's famous privately owned paperboard folding carton manufacturers. RC packages stand ready to assist companies of all sizes with their packaging needs. We proudly create unrivaled service.
        </p>
        <p className="mb-4">
          RC staff has complete technical knowledge about the corrugated box machinery, having worked with automated plants. Their combined experience and sheer unbridled enthusiasm along with stringent Quality Control standards has been instrumental in ensuring exponential growth. Equipped to manufacture 24X7, the firm is an accredited member of the LCCI.
        </p>
        <p>
          We provide solutions that create brand value and make room for business improvement. Our main focus is on maximizing the business value of packaging for our customers.
        </p>
      </div>
      <div className="text-center">
  <h2 className="text-2xl font-semibold mb-8">The RC-Packages Advantage</h2>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
    {[
      { icon: '⏱️', title: '24/7 Manufacturing' },
      { icon: '♻️', title: 'Recyclable Products' },
      { icon: '✔️', title: 'Quality Control' },
      { icon: '📦', title: '100 Tonnes per month' },
      { icon: '🖨️', title: 'In House Printing' },
      { icon: '🏭', title: 'In House Corrugation' },
    ].map((item, index) => (
      <div
        key={index}
        className="flex flex-col items-center justify-center p-5 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105"
      >
        <div className="text-4xl mb-4">{item.icon}</div>
        <p className="text-lg font-medium text-gray-800">{item.title}</p>
      </div>
    ))}
  </div>
</div>

      <div className="mt-10">
        <h2 className="text-center text-2xl font-semibold mb-6">Our Valued Customers</h2>
         <Slider {...sliderSettings}>
    {clientLogos.map((client, index) => (
      <div key={index} className="px-2">
        <div className="h-20 flex items-center justify-center bg-gray-100 rounded-lg shadow-md">
          <p className="text-lg font-medium text-gray-800">{client}</p>
        </div>
      </div>
    ))}
  </Slider>
      </div>
    </div>
  );
};

export default About;
